import { useEffect, useState } from 'react';
import { getAllCountries, getStatesOfCountry } from 'utils/countries-states';

export function useCountriesStates(
  country = 'US',
  state = '',
  setFieldValue = () => {},
  countryFieldName = 'country',
  stateFieldName = 'state'
) {
  const [countries] = useState(
    getAllCountries().map(country => ({
      sortname: country.isoCode,
      value: country.isoCode,
      label: country.name,
      id: country.isoCode,
    }))
  );

  const [states, setStates] = useState([]);

  const [defaultCountry] = useState(countries.find(c => c.value === 'US'));

  useEffect(() => {
    const currentCountry = countries.find(
      c => c.value === country || c.label === country
    );

    const countryValue = currentCountry?.value ?? defaultCountry.value;
    setFieldValue(countryFieldName, countryValue);

    const statesCountry = currentCountry?.id || defaultCountry.id;
    const countryStates = getStatesOfCountry(statesCountry).map(state => {
      const value =
        statesCountry === defaultCountry.id ? state.isoCode : state.name;
      return { value: value, label: state.name, id: state.isoCode };
    });

    setStates(countryStates);

    if (state) {
      const currentState = countryStates.find(s => s.value === state);

      if (!currentState) {
        const labelState = countryStates.find(s => s.label === state);

        if (labelState && statesCountry === defaultCountry.id)
          setFieldValue(stateFieldName, labelState.value);
        else setFieldValue(stateFieldName, '');
      }
    }
  }, [country, state, setStates, countries]);

  return [countries, states];
}
