import React, { useRef, useState } from 'react';
import TextInput from 'components/form-elements/TextInput';
import FileInput from 'components/form-elements/FileInput';
import NumberInput from 'components/form-elements/NumberInput';
import TextArea from 'components/form-elements/TextArea';
import Select from 'components/form-elements/Select';
import FileUpload from 'features/onlineRegistration/FileUpload';
import { FieldArray } from 'formik';
import './step-2.scss';
import CloseIcon from 'svg/CloseIcon';
import Alert from 'components/Alert';

const Step2 = ({ values }) => {
  const photoChooseRef = useRef(null);
  const [showAlert, toggleShowAlert] = useState(false);
  const color = [
    {
      value: 'Yes',
      label: 'Yes',
      id: 0,
    },
    {
      value: 'No',
      label: 'No',
      id: 1,
    },
    {
      value: 'Unknown',
      label: 'Unknown',
      id: 2,
    },
  ];
  return (
    <div className="user-input-form step-2" id="formId">
      {showAlert && (
        <Alert color="danger" msg={'Image size must be less than 5MB'} />
      )}
      <h1 className="heading">2. About Your Vehicle</h1>
      <div className="section">
        <div className="field-group">
          <div className="row">
            <div className="col-md-4">
              <NumberInput name="year" placeholder="Year&#42;" maxlength="4" />
            </div>
            <div className="col-md-4">
              <TextInput name="make" placeholder="Make&#42;" />
            </div>
            <div className="col-md-4">
              <TextInput name="model" placeholder="Model&#42;" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <TextInput name="engine" placeholder="Engine" />
            </div>
            <div className="col-md-4">
              <TextInput name="chassis" placeholder="Chassis" />
            </div>
            <div className="col-md-4">
              <TextInput name="body" placeholder="Body" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <TextInput name="interior" placeholder="Interior Color" />
            </div>
            <div className="col-md-4"> 
              <Select
                name="interiorColor"
                data={color}
                placeholder="Are these colors original?"
                onChangeHandler={() => {}}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <TextInput name="exterior" placeholder="Exterior Color" />
            </div>
            <div className="col-md-4">
              <Select
                name="exteriorColor"
                data={color}
                placeholder="Are these colors original?"
                onChangeHandler={() => {}}
              />
            </div>
          </div>
        </div>

        <FieldArray name="images">
          {({ ...fieldArrayHelpers }) => {
            const addImage = async value => {
              if (value) {
                if (value.size / (1024 * 1024) <= 5) {
                  const base54 = await getBase64(value);
                  const image = {
                    name: value.name,
                    file: base54,
                  };
                  if (values.images.length === 15)
                    fieldArrayHelpers.replace(14, image);
                  else fieldArrayHelpers.push(image);
                } else toggleShowAlert(true);
              }
            };
            const removeImage = index => {
              fieldArrayHelpers.remove(index);
            };

            return (
              <div className="section">
                <h2>Attach Images</h2>
                <p className="gray-color">
                  .JPG or .PNG files only please. 5MB maximum file size.
                </p>
                <div
                  className="field"
                  onClick={() => toggleShowAlert(false)}
                  role="presentation"
                >
                  <FileUpload
                    browseFile={() => photoChooseRef.current.click()}
                    setValue={value => {
                      addImage(value);
                    }}
                  />
                  <FileInput
                    name="images"
                    forwardedRef={photoChooseRef}
                    acceptFiles="image/png,image/jpeg"
                    attachOption="multiple"
                    clickHandler={value => {
                      addImage(value);
                    }}
                    setField={false}
                  />

                  {values.images && values.images.length > 0 && (
                    <div className="label-wrapper">
                      {values.images.map((image, i) => {
                        if (i < 15) {
                          return (
                            <div className="label-Box" key={i}>
                              <label key={i} className="takePhotoName-consign">
                                {image.name ?? ''}{' '}
                                <CloseIcon
                                  color="silver"
                                  position="absolute"
                                  right="10px"
                                  onClick={() => {
                                    removeImage(i);
                                  }}
                                />
                              </label>
                            </div>
                          );
                        } else {
                          removeImage(i);
                          return null;
                        }
                      })}
                    </div>
                  )}
                </div>
              </div>
            );
          }}
        </FieldArray>

        <div className="field">
          <div className="field-area">
            <TextArea
              name="referral"
              placeholder="Additional Notes"
              showErrorMessages={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Step2;
const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
