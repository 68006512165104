import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { getAllCountries } from 'utils/countries-states';
import {
  ONLINE_ONLY_AUCTION,
  ONLINE_TELEPHONE_AUCTION,
  ALL_AUCTION,
  BIDDING_METHODS,
} from 'src/utils/auctionTypes';
import { generateFluidImage } from 'utils/image';
import { getEventDateAverage } from 'shared/helpers/auctions';
import * as moment from 'moment';

export const handleVerifyUser = async (values, verifyUser) => {
  return await verifyLNData(values, verifyUser);
};

export const handleAdminVerifyUser = async (
  values,
  verifyUser,
  userSalesforceId
) => {
  return await verifyLNData(values, verifyUser, userSalesforceId);
};

const verifyLNData = async (values, verifyUser, userSalesforceId) => {
  const countryData = getAllCountries().find(
    country => country.isoCode === values.country
  );
  let verifyUserResp;
  try {
    verifyUserResp = await verifyUser({
      variables: {
        verifiableData: {
          firstName: values.firstName,
          lastName: values.lastName,
          streetAddress1: values.aboutYouAddress1,
          streetAddress2: values.aboutYouAddress2,
          city: values.city,
          state: values.state,
          zipcode: values.zipcode,
          phone: parsePhoneNumberFromString(values.phone, 'US').number,
          country: countryData.isoCode,
          email: values.email,
        },
        ...(userSalesforceId && { userSalesforceId }),
      },
    }).then(
      resp =>
        resp.data?.verifyRegisterToBidData?.verificationStatus ||
        resp.data?.verifyBidderData?.verificationStatus ||
        'Error'
    );
  } catch (err) {
    verifyUserResp = { verificationStatus: 'Error' };
  }
  return verifyUserResp;
};
export function formatAuctions(auctions) {
  return auctions
    .map(auction => {
      return {
        id: auction.objectID,
        name: auction.name,
        campaignId: auction.campaignId,
        date: getEventDateAverage(auction.subEvents),
        imgSrc: auction.fluidAuctionImage
          ? generateFluidImage(
              500,
              auction?.fluidAuctionImage?.file?.url,
              95,
              auction?.fluidAuctionImage?.file?.details?.image?.width,
              auction?.fluidAuctionImage?.file?.details?.image?.height
            )
          : null,
        imgDescription: auction.fluidAuctionImage?.description,
        status: auction.eventStatus,
        eventAttendanceMode: auction.eventAttendanceMode,
        offers: auction.offers,
      };
    })
    .filter(auction => {
      return (
        auction.status !== 'EventCancelled' &&
        auction.status !== 'EventPostponed' &&
        auction.status !== 'EventAnnounced'
      );
    });
}

export function filterAuctionsByBiddingMethod(auctions, buyerStatus) {
  const online = [];
  const inPerson = [];
  const tele = [];
  const filtered = auctions.filter(auction => {
    return (
      auction.status !== 'EventCancelled' &&
      auction.status !== 'EventPostponed' &&
      auction.status !== 'EventAnnounced'
    );
  });
  filtered.forEach(auction => {
    switch (auction.eventAttendanceMode) {
      case ONLINE_ONLY_AUCTION:
        online.push(auction);
        break;
      case ONLINE_TELEPHONE_AUCTION:
        online.push(auction);
        tele.push(auction);
        break;
      case ALL_AUCTION:
        online.push(auction);
        tele.push(auction);
        if (checkCustomerTypeOffer(auction.offers, buyerStatus))
          inPerson.push(auction);
        break;
      default:
        return;
    }
  });
  return {
    [BIDDING_METHODS.ONLINE_BIDDING]: online,
    [BIDDING_METHODS.IN_PERSON_BIDDING]: inPerson,
    [BIDDING_METHODS.TELEPHONE_BIDDING]: tele,
  };
}

export const defaultBuyerStatus = 'Standard';
export function checkCustomerTypeOffer(offers, buyerStatus) {
  if (!offers) return false;

  const eligibleOffers = offers.filter(
    offer =>
      parseInt(offer.eligibleQuantity) === 1 &&
      offer.eligibleCustomerType.some(
        type => type.title === buyerStatus || type.title === defaultBuyerStatus
      )
  );
  return !!eligibleOffers.length;
}
export function getConditionsOfSale(selectedAuctions, auctions) {
  const filteredAuctions = [];
  const conditionsOfSale = [];
  if (selectedAuctions.length > 0) {
    selectedAuctions.forEach(auction => {
      filteredAuctions.push(auctions.find(x => x.objectID === auction));
    });
  }
  filteredAuctions.forEach(auction => {
    conditionsOfSale.push(
      auction.conditionsOfSaleType || 'US Conditions of sale'
    );
  });
  let distinctConditionsOfSale = [...new Set(conditionsOfSale)];
  if (distinctConditionsOfSale.length > 1) {
    return ['US Conditions of sale'];
  }
  return distinctConditionsOfSale;
}
export const buyerStatusTypes = {
  PLATINUM: 'Platinum',
  GOLD: 'Gold',
  COMP: 'Comp',
  COMP_REG: 'Comp Reg',
  STANDARD: 'Standard',
};
const trustedBuyerStatuses = [
  buyerStatusTypes.PLATINUM,
  buyerStatusTypes.GOLD,
  buyerStatusTypes.COMP,
  buyerStatusTypes.COMP_REG,
];

export function checkTrustedBuyerStatus(buyerStatus) {
  return trustedBuyerStatuses.includes(buyerStatus);
}

export function checkFinancialDateOutdated(date, buyerStatus, auctions = []) {
  if (checkTrustedBuyerStatus(buyerStatus)) return false;

  const auctionsHide = auctions.reduce(
    (prev, curr) => prev && curr.hideFinancialDocuments === 'true',
    true
  );
  if (auctionsHide) return false;

  const today = new Date();

  if (date) {
    const submissionDate = new Date(date);
    const months =
      today.getMonth() -
      submissionDate.getMonth() +
      12 * (today.getFullYear() - submissionDate.getFullYear());
    if (months >= 15) {
      return true;
    }
    return false;
  }
  return true;
}

export function checkPersonalDateOutdated(date, buyerStatus, auctions = []) {
  if (checkTrustedBuyerStatus(buyerStatus)) return false;

  const today = new Date();

  const auctionsHide = auctions.reduce(
    (prev, curr) => prev && curr.hidePersonalDocuments === 'true',
    true
  );
  if (auctionsHide) return false;
  if (date) {
    const submissionDate = new Date(date);
    const months =
      today.getMonth() -
      submissionDate.getMonth() +
      12 * (today.getFullYear() - submissionDate.getFullYear());
    if (months >= 15) {
      return true;
    }
    return false;
  }
  return true;
}

export async function createUserInSalesforce(
  values,
  createUser,
  setAlertError,
  setBidderSalesforceId
) {
  const data = {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    mainPhone: values.phone,
    address1: values.aboutYouAddress1,
    address2: values.aboutYouAddress2,
    city: values.city,
    state: values.state,
    zipcode: values.zipcode,
    country: values.country,
    birthDate: moment(values.dateOfBirth).format('YYYY-MM-DD'),
    company: values.company,
  };
  let userId;
  try {
    userId = await createUser({
      variables: {
        bidder: data,
      },
    }).then(resp => {
      return resp.data?.createUserInSalesforce?.id;
    });
  } catch (err) {
    setAlertError(err);
  }
  setBidderSalesforceId(userId);
  return userId;
}

export function removeSeparator(value) {
  return value.replace(/[,\s]/g, '');
}