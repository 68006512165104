import React, { useRef } from 'react';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import TextInput from 'components/form-elements/TextInput';
import Select from 'components/form-elements/Select';
import DatePicker from 'components/form-elements/DatePicker';
import { useMediaQuery } from 'react-responsive';
import NumberInput from 'components/form-elements/NumberInput';

export default ({
  states,
  setFieldValue,
  countries,
  isKiosk,
  handleDoneClick,
  nextButtonRef,
}) => {
  const cityRef = useRef(null);
  const cityRefKiosk = useRef(null);
  const zipcodeRef = useRef(null);

  const isTablet = useMediaQuery({ maxWidth: 992 });
  return (
    <div className="user-input-form">
      <h1 className="heading">
        {isKiosk ? 'Personal Info' : 'Register To Bid'}
      </h1>
      <div className="section">
        <h2 className="sub-heading">About You</h2>
        <div className="field-group">
          <div className="field">
            <TextInput
              name="firstName"
              placeholder="First Name&#42;"
              onKeyDown={handleDoneClick}
            />
          </div>
          <div style={{ marginRight: '12px' }} className="field">
            <TextInput
              name="lastName"
              placeholder="Last Name&#42;"
              onKeyDown={handleDoneClick}
            />
          </div>
          <div className="field date-of-birth">
            <DatePicker
              name="dateOfBirth"
              placeholderText="Date of Birth&#42;"
              onKeyDown={handleDoneClick}
            />
          </div>
        </div>
        <div className="field-group">
          <div className="field">
            <TextInput
              name="company"
              placeholder="Company"
              onKeyDown={handleDoneClick}
            />
          </div>
          <div style={{ marginRight: '12px' }} className="field">
            <PhoneNumber
              name="phone"
              placeholder="Phone&#42;"
              label="&#42;"
              onKeyDown={event => {
                if (
                  event.key.toLowerCase() === 'enter' ||
                  event.keyCode === 13
                ) {
                  nextButtonRef.current.children[isKiosk ? 0 : 1].focus();
                }
              }}
            />
          </div>
          <div className="field">
            <TextInput
              name="email"
              placeholder="Email&#42;"
              disabled={true}
              onKeyDown={handleDoneClick}
            />
          </div>
        </div>
      </div>

      <div className="section">
        <h2 className="sub-heading">Address</h2>
        <div className="field-group">
          <div className="field">
            <TextInput
              name="aboutYouAddress1"
              placeholder="Address 1&#42;"
              onKeyDown={handleDoneClick}
            />
          </div>
          <div className="field">
            <TextInput
              name="aboutYouAddress2"
              placeholder="Address 2"
              onKeyDown={handleDoneClick}
            />
          </div>
        </div>
        <div style={{ gap: '10px' }} className="field-group">
          <div className="field">
            <label htmlFor="country" className="visuallyhidden">
              Choose country
            </label>
            <Select
              ariaLabel="country"
              name="country"
              data={countries}
              placeholder="Country&#42;"
              onChangeHandler={c => {
                setFieldValue('state', '');
                setFieldValue('city', '');
                setFieldValue('zipcode', '');
              }}
              onKeyDown={event => {
                if (
                  event.key.toLowerCase() === 'enter' ||
                  event.keyCode === 13
                ) {
                  isTablet
                    ? cityRefKiosk.current.children[0].children[0].focus()
                    : cityRef.current.children[0].children[0].focus();
                }
              }}
            />
          </div>
          {isTablet && (
            <div className="field">
              <TextInput
                name="city"
                placeholder="City&#42;"
                onKeyDown={handleDoneClick}
                textRef={cityRefKiosk}
              />
            </div>
          )}

          {!isTablet && (
            <div className="field">
              <TextInput
                onKeyDown={handleDoneClick}
                name="city"
                placeholder="City&#42;"
                textRef={cityRef}
              />
            </div>
          )}

          <div className="field">
            <label htmlFor="state" className="visuallyhidden">
              Choose state
            </label>
            <Select
              ariaLabel="state"
              name="state"
              data={states}
              placeholder="State&#42;"
              onKeyDown={event => {
                if (
                  event.key.toLowerCase() === 'enter' ||
                  event.keyCode === 13
                ) {
                  zipcodeRef.current.children[0].children[0].focus();
                }
              }}
            />
          </div>
          <div className="field">
            <NumberInput
              name="zipcode"
              placeholder="Zipcode&#42;"
              onKeyDown={handleDoneClick}
              textRef={zipcodeRef}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
