import React from 'react';
import NumberInput from 'components/form-elements/NumberInput';
import TextInput from 'components/form-elements/TextInput';
import VehiclesSearch from './VehicleSearch';

const AddAnother = ({ index, handler, lots, values, setFieldValue }) => {
  return (
    <div>
      <div className="field">
        <NumberInput
          name={`lotNumber${index}${index}`}
          format="##########"
          placeholder="Lot Number"
          handler={handler}
        />
      </div>
      <div className="field">
        <VehiclesSearch
          options={lots}
          placeholder="Description (Year, Make, Model)"
          fieldName={`description${index}${index}`}
          lotFieldName={`lotNumber${index}${index}`}
          setFieldValue={setFieldValue}
          values={values}
        />
      </div>
      <div className="field">
        <NumberInput
          name={`maximumBid${index}${index}`}
          maxlength={12}
          placeholder="Maximum Bid"
          thousandSeparator={','}
        />
      </div>
    </div>
  );
};
export default AddAnother;
