import React, { useState, useEffect } from 'react';
import SEO from 'components/seo';
import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import {
  getConsignmentInitialValues,
  getConsignmentSchema,
} from 'components/utils/consignmentFormUtils';
import Layout from 'components/Layout';
import Step1 from 'features/consignmentForm/Step1';
import Step2 from 'features/consignmentForm/Step2';
import Chevron from 'components/Chevron/Chevron';
import Navigation from 'components/Navigation/Navigation';
import Footer from 'features/consignmentForm/Footer';
import './consignment-inquiry.scss';
import { useAuth0 } from '@auth0/auth0-react';
import { GET_PROFILE_DATA } from 'services/graphql/queries/profile';
import { useMutation } from '@apollo/client';
import Spinner from 'components/Spinner';
import { SEND_CONSIGNMENT_EMAIL } from 'services/graphql/queries/consigmnet';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import Alert from 'components/Alert';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { SLUGS } from 'utils/webPageTypesConstants';
import { amplitudeEvents, trackEvent } from 'utils/amplitude';

const validationSchemaArray = getConsignmentSchema();

export default () => {
  const [step, setStep] = useState(0);
  const [isInitialValid, setIsInitialValid] = useState(false);
  const slugs = useWebPagesSlugs();
  const handleNext = props => {
    const { isValid, validateForm, setTouched } = props;

    if (isValid) {
      if (step < 2) setStep(step + 1);
      window.scrollTo(0, 0);
      validateForm();
      setTouched({});
    }
  };

  const handleBack = ({ setErrors }) => {
    if (step === 0) {
      navigate(`/${slugs[SLUGS.consignment]}`);
      return;
    }
    setStep(step - 1);
    setErrors({});
  };
  const [sendEmail, emailState] = useMutation(SEND_CONSIGNMENT_EMAIL);
  const { isAuthenticated } = useAuth0();
  const { loading, data } = useAuthenticatedQuery(GET_PROFILE_DATA, {
    skip: !isAuthenticated,
  });

  const initialValues = getConsignmentInitialValues();
  if (data) initializeUserData(initialValues, data.profile);

  useEffect(() => {
    validationSchemaArray[0]
      .validate(initialValues)
      .then(res => setIsInitialValid(true))
      .catch(err => setIsInitialValid(false));
  }, [initialValues]);

  const sendEmailError = emailState.error?.message;

  return (
    <Layout hideFooter={true}>
      <SEO title="Consignment Enquiry" />
      {loading && <Spinner />}
      <Chevron amount={step + 1} />
      <div className="consignment-inquiry">
        <div className="body">
          {emailState.loading && <Spinner />}
          {sendEmailError && <Alert color="danger" msg={sendEmailError} />}
          <Formik
            validateOnMount
            initialValues={initialValues}
            validationSchema={validationSchemaArray[step]}
            onSubmit={values => {}}
            enableReinitialize
            isInitialValid={isInitialValid}
          >
            {props => {
              const { values, setFieldValue, isSubmitting } = props;
              return (
                <>
                  <Form>
                    {step === 0 && (
                      <Step1
                        country={values.country}
                        state={values.state}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    {step === 1 && (
                      <Step2
                        country={values.country}
                        setFieldValue={setFieldValue}
                        {...props}
                      />
                    )}
                  </Form>
                  <Footer
                    step={step}
                    handleNext={() => handleNext(props)}
                    handleBack={() => handleBack(props)}
                    isSubmitting={isSubmitting}
                    {...props}
                    submit={async () => {
                      const { images, ...fields } = values;
                      await props.submitForm();
                      await sendEmail({
                        variables: {
                          fields: fields,
                          attachments: images.map(image => image.file),
                        },
                      });
                      window.fbq('track', 'consignment-inquiry');
                      if (window.dataLayer)
                        window.dataLayer.push({
                          event: 'consignment-submit-form',
                        });
                      await navigate(`/${slugs[SLUGS.consignment]}`, {
                        state: { emailSent: true },
                      });
                      await trackEvent(amplitudeEvents.SUBMIT_CONSIGN_FORM, {
                        email_address: fields?.email,
                      });
                    }}
                    showSubmitModal={
                      emailState.data &&
                      emailState.data.SendConsignmentEmail.code === 202
                    }
                    closeSubmitModal={async () =>
                      await navigate(`/${slugs[SLUGS.consignment]}`, {
                        state: { emailSent: true },
                      })
                    }
                    consignmentSlug={`/${slugs[SLUGS.consignment]}`}
                  />
                </>
              );
            }}
          </Formik>
        </div>
        {/* <Navigation step={step} maxStep={2} /> */}
      </div>
    </Layout>
  );
};

const initializeUserData = (defaultValues, userData) => {
  for (const key of Object.keys(defaultValues)) {
    if (userData[key]) defaultValues[key] = userData[key];
  }
  if (!!userData.mainPhone) defaultValues.phone = userData.mainPhone;
};
