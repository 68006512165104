import * as React from 'react';

const Icon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.5"
        y="2.5"
        width="251"
        height="251"
        rx="125.5"
        stroke="white"
        stroke-width="7"
      />
      <path
        d="M140.192 118.205L187.848 64H176.556L135.158 111.056L102.117 64H64L113.975 135.163L64 192H75.2914L118.982 142.296L153.883 192H192L140.192 118.205ZM124.722 135.787L119.65 128.697L79.3634 72.3294H96.7094L129.232 117.837L134.282 124.927L176.551 184.076H159.205L124.722 135.787Z"
        fill="white"
      />
    </svg>
  );
};

export default Icon;
