import React, { Fragment, useEffect, useState } from 'react';
import PaymentInfo from './PaymentDetails';
import BillingInformation from './BillingInformation';
import styles from '../CheckoutForms.module.scss';
import CheckboxInput from 'components/form-elements/CheckboxInput';

const UserInfo = ({
  setFieldValue,
  values,
  initialValues,
  amount,
  cart,
  RemoveItem,
  isUk = false,
}) => {
  const [isShippingAddress, setIsShippingAddress] = useState(true);

  const billingFieldsNames = {
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'receiptEmail',
    addressName1: 'line1',
    addressName2: 'line2',
    city: 'city',
    state: 'state',
    postalCode: 'postalCode',
    country: 'country',
  };

  const shippingFieldsNames = {
    addressName1: 'shippingLine1',
    addressName2: 'shippingLine2',
    city: 'shippingCity',
    state: 'shippingState',
    postalCode: 'shippingPostalCode',
    country: 'shippingCountry',
  };

  useEffect(() => {
    if (!isShippingAddress) {
      // set billing address fields to shipping address fields
      Object.keys(shippingFieldsNames).forEach(key =>
        setFieldValue(shippingFieldsNames[key], values[billingFieldsNames[key]])
      );
    }
  }, [
    isShippingAddress,
    values.line1,
    values.line2,
    values.city,
    values.state,
    values.postalCode,
    values.country,
  ]);

  useEffect(() => {
    if (isShippingAddress) {
      // set shipping address initial values to shipping address fields
      Object.keys(shippingFieldsNames).forEach(key =>
        setFieldValue(
          shippingFieldsNames[key],
          initialValues[shippingFieldsNames[key]]
        )
      );
    }
  }, [isShippingAddress]);

  return (
    <Fragment>
      <PaymentInfo
        amount={amount}
        items={cart}
        RemoveItem={RemoveItem}
        isUk={isUk}
      />
      <BillingInformation
        setFieldValue={setFieldValue}
        state={values.state}
        country={values.country}
        isUk={isUk}
        fieldsNames={billingFieldsNames}
      />
      <h2 className={styles.title}>Shipping Information</h2>
      <CheckboxInput
        handleSelect={() => setIsShippingAddress(prev => !prev)}
        title={'Same as Billing Address'}
      />
      {isShippingAddress && (
        <BillingInformation
          country={values.shippingCountry}
          state={values.shippingState}
          setFieldValue={setFieldValue}
          removeTitle={true}
          removeName={true}
          removeEmail={true}
          fieldsNames={shippingFieldsNames}
        />
      )}
    </Fragment>
  );
};

export default UserInfo;
